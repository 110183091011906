<template>
  <div class="searchbar-wrapper">
    <input
      class="searchbar"
      type="text"
      name="search"
      placeholder="Search..."
      v-model="searchUser"
      @change="searchUsers"
      @keyup="searchUsers"
    />
    <button class="search-button" @click="searchUsers">
      <font-awesome-icon icon="search" />
    </button>
  </div>
</template>

<script>
export default {
  name: "SearchBar",
  props: ["updateSelected"],
  data() {
    return {
      searchUser: "",
      setInterval: null
    };
  },
  watch: {
    updateSelected(time) {
      time > 0 ? this.interval(time) : clearInterval(this.setInterval);
    }
  },
  methods: {
    searchUsers() {
      let data = {};
      data.search = this.searchUser;
      this.$emit("searchUsers", data);
    },
    interval(time) {
      this.setInterval = setInterval(this.searchUsers, time);
    }
  }
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.searchbar-wrapper {
  margin: 30px;
  padding: 2px;
  background-color: var(--searchbar-bg-color);
  border-radius: 3px;
  border: 1px solid #a3a3a3;
}

.searchbar {
  width: calc(100% - 50px);
  padding: 7px;
  font-weight: bold;
  color: var(--text-color);
  background-color: var(--searchbar-bg-color);
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border: none;
  outline: none;

  &::placeholder {
    color: #bcbcbc;
    opacity: 1;
  }
}

button.search-button {
  width: 50px;
  padding: 7px 10px;
  color: #bcbcbc;
  background-color: var(--searchbar-bg-color);
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border: none;
  outline: none;

  &:hover {
    background-color: var(--button-hover-color);
  }
}
</style>
