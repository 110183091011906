const optionsAdmin = {
  optionsUpdate: [
    { value: 0, text: "Pause" },
    { value: 10000, text: "Every 10 sec" },
    { value: 15000, text: "Every 15 sec" },
    { value: 30000, text: "Every 30 sec" },
    { value: 100000, text: "Every 1 min" },
    { value: 500000, text: "Every 5 min" }
  ],
  fieldsGeneral: ["id", "username", "total_quotes", "avg_time"],
  fieldsGeneralGlt: [
    "id",
    "username",
    "total_quotes",
    "can_not_quote",
    "failed"
  ],
  fieldsGeneralForClient: [
    "client_id",
    "name",
    "not_quote",
    "sent",
    "failed",
    "non_ltl",
    "incomplete"
  ],
  fieldsAutoquote: ["id", "username", "total_quotes"],
  fieldsSpecific: [
    "custom_id",
    "created_at",
    "response_time",
    "status",
    "last_modified",
    "email_subject"
  ],
  fieldsHistory: ["user_id", "description"],
  optionsQueue: [
    { value: "", text: "Select Queue" },
    { value: "All", text: "All" },
    { value: 7, text: "Total Sent" },
    { value: 10, text: "NON LTL" },
    { value: 6, text: "Incomplete" },
    { value: 8, text: "Webhook Faileds" }
  ],
  optionsHistory: [
    { value: "", text: "Select Status Quote" },
    { value: 2002, text: "Quote sent to NON LTL" },
    { value: 2004, text: "Quote assigned by user" },
    { value: 2009, text: "Quote sent to TAI by user" },
    { value: 5001, text: "User logged" },
    { value: 5002, text: "User change information" },
    { value: 4204, text: "Failed to assing quote by a user" },
    { value: 4501, text: "Failed user login" },
    { value: 2010, text: "Quote sent by the automatic autoquoter" },
    { value: 2001, text: "Quote deleted" }
  ]
};
module.exports = optionsAdmin;
