<template>
  <div class="user-modal">
    <b-modal
      @hide="hideModal"
      ref="modal-user"
      id="modal-user"
      :title="userInstruction"
    >
      <b-form>
        <b-form-group id="input-group-1" label="Username:" label-for="input-1">
          <b-form-input
            id="input-1"
            type="text"
            placeholder="Enter username"
            v-model="username"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-2" label="Password:" label-for="input-2">
          <b-form-input
            type="password"
            id="input-2"
            placeholder="Enter password"
            required
            v-model="password"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="input-group-3"
          label="Organization"
          label-for="select-1"
        >
          <b-form-select
            v-model="selectedOrganization"
            :options="optionsOrganization"
          ></b-form-select>
        </b-form-group>
        <b-form-group id="input-group-4" label="Superuser" label-for="select-2">
          <b-form-select
            v-model="selectedSuperuser"
            :options="optionsSuperuser"
          ></b-form-select>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100 button-modal">
          <b-button
            variant="danger"
            size="sm"
            class="button-modal"
            @click="$bvModal.hide('modal-user')"
          >
            Cancel
          </b-button>
          <b-overlay
            :show="busy"
            rounded
            opacity="0.6"
            spinner-small
            class="d-inline-block"
          >
            <b-button
              variant="info"
              size="sm"
              class="button-modal"
              @click="sendInfo(userInstruction)"
            >
              {{ userInstruction }}
            </b-button>
          </b-overlay>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import Swal from "sweetalert2";
export default {
  name: "UsersManagement",
  props: ["showModal", "userInstruction", "editUserInfo"],
  data() {
    return {
      username: "",
      password: "",
      optionsSuperuser: [
        { value: null, text: "Please select an option" },
        { value: true, text: "Yes" },
        { value: false, text: "Not" }
      ],
      selectedSuperuser: null,
      selectedOrganization: null,
      optionsOrganization: [{ value: null, text: "Please select an option" }],
      render: 0,
      busy: false
    };
  },
  watch: {
    showModal() {
      this.showModal ? this.showModalUser() : "";
    },
    editUserInfo() {
      this.username = this.editUserInfo.username;
    }
  },
  methods: {
    async showModalUser() {
      let response = await this.$store.dispatch(
        "usersManagement/getOrganizationOptions"
      );
      for (let i in response) {
        this.optionsOrganization.push({
          value: response[i].id,
          text: response[i].name
        });
      }
      this.$refs["modal-user"].show();
    },
    hideModal() {
      this.$emit("closeModal");
    },
    async createUserEndpoint() {
      let body = this.bodyConstructor();
      let confirmationUserCreate = this.validationCreateUser();
      if (confirmationUserCreate) {
        this.busy = true;
        let response = await this.$store.dispatch(
          "usersManagement/createNewUser",
          body
        );
        response.status === "201"
          ? this.swalConfirmation("Create")
          : this.swalError();
      } else {
        this.swalErrorCreate();
      }
    },
    async editUserEndpoint() {
      let body = this.bodyConstructor();
      body.userId = this.editUserInfo.id;
      body = this.bodyFormat(body);
      this.busy = true;
      let response = await this.$store.dispatch(
        "usersManagement/editUser",
        body
      );
      response.status === "204"
        ? this.swalConfirmation("Edit")
        : this.swalError();
    },
    bodyConstructor() {
      let body = {};
      body.organization_id = this.selectedOrganization;
      body.username = this.username;
      body.is_superuser = this.selectedSuperuser;
      body.password = this.password;
      return body;
    },
    bodyFormat(body) {
      for (let i in body) {
        if (body[i] === "" || body[i] == null) {
          delete body[i];
        }
      }
      return body;
    },
    async sendInfo(userInstruction) {
      userInstruction === "Create User"
        ? await this.createUserEndpoint()
        : userInstruction === "Edit User"
        ? await this.editUserEndpoint()
        : "";
    },
    clearModal() {
      this.username = "";
      this.password = "";
      this.selectedSuperuser = null;
      this.selectedOrganization = null;
    },
    validationCreateUser() {
      if (
        this.username === "" ||
        this.password === "" ||
        this.selectedOrganization == null ||
        this.selectedSuperuser == null
      ) {
        return false;
      }
      return true;
    },
    swalConfirmation(textSwal) {
      Swal.fire(
        "Great!",
        `The user was ${textSwal} successfully.`,
        "success"
      ).then(
        () => (this.busy = false),
        this.clearModal(),
        this.$refs["modal-user"].hide()
      );
      this.$emit("getInfoTableAgain");
    },
    swalError() {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `There is something wrong please try again later`
      }).then(() => this.$refs["modal-user"].hide());
    },
    swalErrorCreate() {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `All fields are required!`
      });
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .btn-info {
  color: #ffffff;
  background-color: #47a3b9;
  border-radius: 15px;
  text-transform: capitalize;
  border: none;
  padding: 10px 10px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px,
    rgba(0, 0, 0, 0.19) 0px 6px 20px 0px;
  &:enabled {
    transition: all 0.1s ease;

    &:hover {
      background-color: var(--button-hover-color);
      transform: scale(1.05);
    }
  }
}
::v-deep .btn-danger {
  color: #ffffff;
  background-color: red;
  border-radius: 15px;
  text-transform: capitalize;
  border: none;
  padding: 10px 10px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px,
    rgba(0, 0, 0, 0.19) 0px 6px 20px 0px;
  &:enabled {
    transition: all 0.1s ease;

    &:hover {
      background-color: var(--button-hover-color);
      transform: scale(1.05);
    }
  }
}
.button-modal {
  display: inline-block;
  justify-content: center;
  text-align: center;
  margin: 5px;
}
</style>
