<template>
  <div class="admin-nav">
    <b-nav tabs class="admin-nav__tabs" align="left">
      <b-nav-item>
        <router-link to="/AdminPanel">General</router-link>
      </b-nav-item>
      <b-nav-item
        ><router-link to="/AdminPanelUsers">Users</router-link></b-nav-item
      >
      <b-nav-item>
        <router-link to="/UsersManagement">Users Management</router-link>
      </b-nav-item>
    </b-nav>
  </div>
</template>

<script>
export default {
  name: "AdminNav",
  components: {}
};
</script>

<style scoped lang="scss">
.admin-nav {
  margin: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 90vw;
}
.nav-tabs {
  border-bottom: 1px solid #a3a3a3;
  width: 90vw;
}
::v-deep a {
  color: #af71b3;
  font-weight: bold;
  text-decoration: none;
}
::v-deep a:hover {
  background-color: #ffffff;
  color: #af71b3;
}
</style>
