<template>
  <div class="select-organization">
    <div class="quotes-option-filter">
      <span>
        <font-awesome-icon
          class="link-icon quotes-option-filter__icon"
          icon="filter"
        />
      </span>
      <select
        @change="selectOrganization($event)"
        class="quotes-option-filter__select"
      >
        <option
          :key="index"
          :value="option.value"
          v-for="(option, index) in optionsOrganization"
        >
          {{ option.text }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: "UsersManagement",
  data() {
    return {
      optionsOrganization: [{ value: null, text: "Select an organization" }]
    };
  },
  methods: {
    async getInfoOrganizations() {
      let response = await this.$store.dispatch(
        "usersManagement/getOrganizationOptions"
      );
      for (let i in response) {
        this.optionsOrganization.push({
          value: response[i].id,
          text: response[i].name
        });
      }
    },
    selectOrganization(event) {
      this.$emit("infoOrganization", event.target.value);
    }
  },
  async created() {
    await this.getInfoOrganizations();
  }
};
</script>
<style scoped lang="scss">
.quotes-option-filter {
  margin: 20px 30px 20px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 45px;
  border: 1px solid #a3a3a3;
  padding: 5px;
  border-radius: 6px;
  color: #a3a3a3;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px,
    rgba(0, 0, 0, 0.19) 0px 6px 20px 0px;
  &__icon {
    height: 20px;
    margin: 5px;
    color: #7c7c7c;
  }
  &__select {
    color: var(--text-color);
    border: none;
    background: none;
    font-weight: bold;
  }
}
</style>
