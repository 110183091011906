<template>
  <div class="users-management">
    <AdminNav />
    <b-button
      class="create-button"
      variant="outline-info"
      @click="showModalUserCreate"
      >Create new user</b-button
    >
    <div class="users-management__form">
      <div class="overflow-auto">
        <SearchUsers @searchUsers="searchUsers" />
        <b-table
          id="my-table"
          responsive="true"
          :fields="fields"
          :items="items"
          :per-page="0"
          :current-page="currentPage"
          :busy="loading"
        >
          <template v-slot:cell(delete_user)="row">
            <span
              class="delete-icon"
              :key="row.label"
              @click="deleteUser(row.item.id)"
            >
              <font-awesome-icon class="icon-delete" icon="trash" />
            </span>
          </template>
          <template v-slot:cell(edit_user)="row">
            <span
              class="delete-icon"
              :key="row.label"
              @click="showModalUserEdit(row.item)"
            >
              <font-awesome-icon class="icon-edit" icon="user-edit" />
            </span>
          </template>
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="spinner-text">Loading history...</strong>
            </div>
          </template>
        </b-table>
        <div class="pagination-users">
          <p class="pagination-users__p">
            Page {{ currentPage }} of {{ totalPages }}
          </p>
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="my-table"
            size="sm"
            align="right"
            @change="getInfoTablePagination"
          ></b-pagination>
        </div>
      </div>
    </div>
    <div>
      <UserModal
        @getInfoTableAgain="getInfoTableAgain"
        @closeModal="closeModal"
        :showModal="showModal"
        :userInstruction="userInstruction"
        :editUserInfo="editUserInfo"
      />
    </div>
  </div>
</template>

<script>
import AdminNav from "../components/AdminNav.vue";
import SearchUsers from "../components/SearchUsers.vue";
import UserModal from "../components/UserModal.vue";
import Swal from "sweetalert2";
export default {
  name: "UsersManagement",
  components: {
    AdminNav,
    SearchUsers,
    UserModal
  },
  data() {
    return {
      items: [],
      fields: [
        "username",
        "is_active",
        "is_superuser",
        "delete_user",
        "edit_user"
      ],
      loading: false,
      skip: 0,
      perPage: 10,
      currentPage: 1,
      totalPages: "",
      rows: null,
      showModal: false,
      userInstruction: "",
      editUserInfo: null
    };
  },

  async created() {
    await this.totalUsers();
    await this.getAllUsers();
    this.pagesTotal();
  },
  methods: {
    closeModal() {
      this.showModal = false;
    },
    showModalUserCreate() {
      this.showModal = true;
      this.userInstruction = "Create User";
    },
    showModalUserEdit(infoUser) {
      this.editUserInfo = infoUser;
      this.showModal = true;
      this.userInstruction = "Edit User";
    },
    async getAllUsers() {
      this.loading = true;
      let params = {};
      params.skip = this.skip;
      params.limit = 10;
      this.items = await this.$store.dispatch(
        "usersManagement/getAllUsers",
        params
      );
      this.loading = false;
    },
    async totalUsers() {
      let response = await this.$store.dispatch(
        "usersManagement/getTotalUsers"
      );
      this.rows = response.count;
    },
    getInfoTablePagination(currentPage) {
      this.skip = (currentPage - 1) * this.perPage;
      this.getAllUsers();
    },
    deleteUser(userId) {
      Swal.fire({
        icon: "warning",
        title: "Are you sure?",
        text: "After it is deleted the user will not be able to recover it!",
        showCancelButton: true
      }).then((value) => {
        value.isConfirmed ? this.deleteUserEndpoint(userId) : "";
      });
    },
    async deleteUserEndpoint(userId) {
      let response = await this.$store.dispatch(
        "usersManagement/deleteUser",
        userId
      );
      response.status === "204" ? this.swalConfirmation() : "";
    },
    swalConfirmation() {
      Swal.fire(
        "Great!",
        `The user was delete successfully.`,
        "success"
      ).then(() => this.getAllUsers());
    },
    pagesTotal() {
      this.totalPages = this.rows / this.perPage;
      if (this.totalPages % 1 === 0) {
        return this.totalPages;
      } else {
        this.totalPages = Math.trunc(this.totalPages) + 1;
      }
    },
    getInfoTableAgain() {
      this.getAllUsers();
    },
    async searchUsers(value) {
      let params = {};
      params.username_search = value.search;
      this.items = await this.$store.dispatch(
        "usersManagement/getAllUsers",
        params
      );
    }
  }
};
</script>
<style scoped lang="scss">
.users-management {
  &__form {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 70px;
  }
}
.create-button {
  display: flex;
  margin-left: 17px;
}
.delete-icon {
  cursor: pointer;
}
.icon-delete {
  color: red;
}
.icon-edit {
  color: #61ea38;
}
.overflow-auto {
  border-radius: 3px;
  margin: 15px 30px 30px 30px;
  box-shadow: 1px 2px 10px 0px #888888;
}
::v-deep .table > thead {
  background-color: #ebebeb;
  color: #00557e;
  font-weight: bold;
}
::v-deep .align-middle {
  color: #00557e;
}
::v-deep .table {
  color: #666666;
  background: #f8f8f8;
}
.spinner-text {
  color: #00557e;
}
.pagination-users {
  margin: 10px;
  display: flex;
  align-items: flex-end;
  justify-content: space-evenly;
  &__p {
    color: #666666;
  }
}
::v-deep .page-link {
  background: var(--app-font-color);
  color: #ffffff;
}
::v-deep .page-item.active .page-link {
  background: var(--app-font-color);
  color: white;
  border: 1px solid #00557e;
}
</style>
