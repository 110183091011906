<template>
  <div class="admin-panel">
    <AdminNav />
    <!-- <img class="logo-img" src="../assets/tai-logo.png" alt="Tai-logo" /> -->
    <img
      class="logo-img"
      src="../assets/Logo-Hypercubes.png"
      alt="hypercubes-logo"
    />
    <div class="quotes">
      <div class="quotes-datepicker">
        <div class="quotes-datepicker__container">
          <div>
            <label class="quotes-datepicker__title" for="startDate"
              >Start date</label
            >
            <input
              class="quotes-datepicker__input"
              type="date"
              v-model="startDate"
              id="startDate"
              name="trip-start"
              required
            />
          </div>
          <div>
            <label class="quotes-datepicker__title" for="endDate"
              >End date</label
            >
            <input
              class="quotes-datepicker__input"
              type="date"
              v-model="endDate"
              id="endDate"
              name="trip-start"
              required
            />
          </div>
        </div>
        <div class="quotes-datepicker__container">
          <div>
            <label class="quotes-datepicker__title" for="endDate"
              >Start Time</label
            >
            <input
              class="quotes-datepicker__input"
              type="time"
              v-model="startTime"
              id="startTime"
              name="trip-start"
              required
            />
          </div>
          <div>
            <label class="quotes-datepicker__title" for="endDate"
              >End Time</label
            >
            <input
              class="quotes-datepicker__input"
              type="time"
              v-model="endTime"
              id="endTime"
              name="trip-start"
              required
            />
          </div>
        </div>
      </div>
      <SelectOrganization @infoOrganization="organizationSelected" />
      <!-- Download report by client  -->
      <!-- <div v-if="this.organization == 3" class="filter-options-client">
        <div class="quotes-option-filter">
          <span>
            <font-awesome-icon
              class="link-icon quotes-option-filter__icon"
              icon="filter"
            />
          </span>
          <select class="quotes-option-filter__select">
            <option value="1">All</option>
            <option value="0">D&R</option>
            <option value="1">GLT</option>
          </select>
        </div>
      </div> -->
      <div class="quotes-option-filter">
        <span>
          <font-awesome-icon
            class="link-icon quotes-option-filter__icon"
            icon="filter"
          />
        </span>
        <select
          @change="selectQueue($event)"
          class="quotes-option-filter__select"
        >
          <option
            :key="index"
            :value="option.value"
            v-for="(option, index) in optionsAdmin.optionsQueue"
          >
            {{ option.text }}
          </option>
        </select>
      </div>
      <div>
        <input
          v-b-popover.hover.top="'Only numbers greater than zero are accepted'"
          class="quotes-option-filter"
          type="number"
          placeholder="Minutes interval"
          v-model="minutesInterval"
        />
      </div>
    </div>
    <b-overlay
      :show="busy"
      rounded
      opacity="0.6"
      spinner-small
      class="d-inline-block"
    >
      <OptionButton title="Download Report" @click.native="getInfoGeneral" />
    </b-overlay>
  </div>
</template>
<script>
import OptionButton from "@/components/OptionButton";
import AdminNav from "@/components/AdminNav";
import optionsAdmin from "@/utils/adminPanelOptions";
import SelectOrganization from "../components/SelectOrganizations.vue";
export default {
  name: "AdminPanel",
  components: {
    OptionButton,
    AdminNav,
    SelectOrganization
  },
  data() {
    return {
      optionsAdmin: optionsAdmin,
      busy: false,
      startDate: "",
      endDate: "",
      startTime: "05:00",
      endTime: "18:00",
      minutesInterval: "",
      quoteOption: "",
      infoGeneral: null,
      queueSelect: null,
      organization: null
    };
  },
  methods: {
    organizationSelected(organization) {
      this.organization = organization;
    },
    selectQueue(event) {
      this.queueSelect = event.target.value;
      return this.queueSelect;
    },
    async getInfoGeneral() {
      this.busy = true;
      let infoGeneralFile;
      let params = {};
      params.status = this.statatusListConstructor(
        this.arrayStatusConstructor()
      );
      params.initial_date = this.startDate + " " + `${this.startTime}:00`;
      params.final_date = this.endDate + " " + `${this.endTime}:00`;
      params.response_type = "csv";
      params.organization_id = this.organization;
      let formatParams = this.formatDateUtc(params);
      if (this.minutesInterval != "") {
        params.minutesInterval = this.minutesInterval;
        infoGeneralFile = await this.$store.dispatch(
          "adminPanelGeneral/getReportQuotesPerInterval",
          formatParams
        );
      } else {
        infoGeneralFile = await this.$store.dispatch(
          "adminPanelGeneral/getInfoGeneral",
          formatParams
        );
      }
      infoGeneralFile != undefined ? this.downloadFile(infoGeneralFile) : "";
      this.busy = false;
    },
    formatDateUtc(params) {
      let initialDate = new Date(params.initial_date);
      let endDate = new Date(params.final_date);
      params.initial_date = initialDate.toISOString();
      params.final_date = endDate.toISOString();
      return params;
    },
    downloadFile(file) {
      let uriContent = "data:application/csv," + encodeURIComponent(file);
      let link = document.createElement("a");
      link.setAttribute("href", uriContent);
      link.setAttribute(
        "download",
        `TAI-report${this.startDate}-${this.endDate}.csv`
      );
      link.click();
    },
    statatusListConstructor(arrayStatus) {
      let statusList = "?";
      arrayStatus.forEach((status) => {
        statusList += "status=" + status + "&";
      });
      return statusList.substring(0, statusList.length - 1);
    },
    arrayStatusConstructor() {
      let arrayStatus = [];
      if (
        this.queueSelect === "All" ||
        this.queueSelect === "" ||
        this.queueSelect == null
      ) {
        arrayStatus = [6, 7, 8, 10];
      } else {
        arrayStatus.push(this.queueSelect);
      }
      return arrayStatus;
    }
  }
};
</script>
<style scoped lang="scss">
.admin-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.logo-img {
  width: 14rem;
  margin: 0.313em;
}
.quotes {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.quotes-option-filter {
  margin: 20px 30px 20px 0px;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 45px;
  border: 1px solid #a3a3a3;
  padding: 5px;
  border-radius: 6px;
  color: #a3a3a3;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px,
    rgba(0, 0, 0, 0.19) 0px 6px 20px 0px;
  &__icon {
    height: 20px;
    margin: 5px;
    color: #7c7c7c;
  }
  &__select {
    color: var(--text-color);
    border: none;
    background: none;
    font-weight: bold;
  }
}

.quotes-datepicker {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background: #fafafa;
  margin: 20px;
  align-items: center;
  justify-content: center;
  &__title {
    margin: 5px;
  }
  &__input {
    background-color: transparent;
    padding: 5px;
    border-left: 0px;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 1px solid var(--app-font-color);
    color: #a3a3a3;
  }
}

/* MEDIA QUERIES: RESPONSIVE DESIGN */
@media (max-width: 500px) {
  .admin-panel {
    height: 100vh;
  }
  .quotes {
    flex-direction: column;
  }
  .quotes-datepicker {
    margin: 10px;
  }
  .quotes-option-filter {
    margin: 15px 15px 20px 15px;
  }
}
@media (min-width: 501px) and (max-width: 768px) {
  .admin-panel {
    height: 90vh;
  }
  .quotes {
    flex-direction: column;
  }
  .quotes-datepicker {
    margin: 10px;
  }
  .quotes-option-filter {
    margin: 15px 15px 20px 15px;
  }
}
</style>
